/* You can add global styles to this file, and also import other style files */
//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "./bootstrap/bootstrap";
@import "./spinner";
@import "./utils";
@import "./rtl";
@import "./responsive";
@import './swimline-datatable-16.1.1.css';
@import './tabledata.bootstrap.scss';
@import './table-custom';
@import '../../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './innerPagesMenu';
@import './theme/colors';
@import './theme/theme';
@import "./theme/animations";
@import "./auth-components";
@import "./ionfi-checkbox";

$topnav-background-color: #4b0b79;
$topnav-background-color: #4E00C6;

$margin-card: 15px !important;
$padding-card: 20px !important;
:root {
  --activity-table-body-vh: 269px
}

;
:root {
  --rdc-table-vh: 515px
}

;

.min-header-height {
  height: 67px !important;
}

.pl-7 {
  padding-left: 7.5px;
}

.pr-7 {
  padding-right: 7.5px;
}

.weight-500 {
  font-weight: 500;
}

input {
  background: var(--background-primary) !important;
  color: var(--text-primary) !important;
}

.list-group-item {
  border-color: var(--border);
  background-color: var(--background-sidebar-sidebar);
  color: var(--text-primary);
}

textarea {
  background: var(--background-primary) !important;
  color: var(--text-primary) !important;
}

.input-group-text {
  background: var(--background-primary) !important;
  color: var(--text-primary) !important;
}

select {
  background: var(--background-primary) !important;
  color: var(--text-primary) !important;
}

.mat-input-underline {
  background-color: transparent;
}

.mat-datepicker-toggle-default-icon {
  color: var(--text-primary);
}

.mat-input-placeholder {
  color: var(--text-primary) !important;
}

.mat-input-element {
  background-color: transparent !important;
}

.ionfi-black-opacity-7 {
  color: $ionfi-black-opacity-7;
}

.ionfi-black-opacity-9 {
  color: $ionfi-black-opacity-9;
}

.mat-input-flex {
  background: var(--background-primary) !important;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}

.current-balance {
  padding-right: 7.5px;
  width: 100%;

  .card {
    padding-top: $padding-card;
    padding-left: $padding-card;
    padding-right: $padding-card;
    padding-bottom: 4px;
  }
}

.canvas-max-height {
  max-height: 200px;
}

.total-balance {
  padding-left: 7.5px;
  width: 100%;

  .card {
    padding-top: $padding-card;
    padding-left: $padding-card;
    padding-right: $padding-card;
    padding-bottom: 4px;
  }
}

.green-indicator {
  color: #4CAF50;
  position: absolute;
  background: #4CAF50;
  width: 5px;
  top: 20px;
  height: 5px;
  border-radius: 50%;
}

bfv-messages {
  font-size: 14px;
}

body {
  background: var(--background-secondary);
  color: var(--text-primary);
  font-family: 'Roboto', sans-serif;
}

a {
  color: var(--purple);
}

.custom-modal-dialog {
  max-height: 95% !important;
}

.mat-form-field-flex {
  color: black;
}

.mat-form-field-infix {
  border: 0 !important;
}

.form-group-date label {
  margin: 0;
}

.modal-xl {
  max-width: 1400px;
}


.help-block {
  &.ng-star-inserted {
    display: block;
    font-style: italic;
    color: crimson;
  }
}

%form-custom {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #f1f1f1;
  color: white;
  top: 10px;
  height: 30px;
  width: 30px;
  border: 1px solid #d4d3d3;
  cursor: pointer;
  margin-right: 7px;
  outline: none;
}

%form-custom:checked::before {
  position: absolute;
  font: 13px/1 'Open Sans', sans-serif;
  left: 11px;
  top: 7px;
  content: '\02143';
  transform: rotate(40deg);
}

%form-custom:hover {
  background-color: #f7f7f7;
}

%form-custom:checked {
  background-color: #4b0b79;
}

.form-custom-label {
  font: 300 16px/1.7 'Open Sans', sans-serif;
  color: #666;
  cursor: pointer;
}

.form-radio {
  @extend %form-custom;
  border-radius: 50px;
}

.form-checkbox {
  @extend %form-custom;
  border-radius: 2px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch.switch-sm {
  width: 46px;
  height: 20px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider.slider-sm:before {
  height: 12px;
  width: 12px;
}

input:checked + .slider {
  background-color: #4b0b79;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4b0b79;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.cdk-overlay-container {
  z-index: 1200 !important;
}

input:checked + .slider, .panel-collapse .panel-body .list-group-item {
  background-color: var(--background-primary) !important;
}

.filedrop {
  border: 2px dotted #4e00c66b;
  border-radius: 2px;
  padding: 8rem;

  div {
    color: var(--purple) !important;
    background: transparent;

    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  span {
    color: grey;

    :hover {
      background: var(--upload-background);
    }
  }
}


input:focus + .slider {
  box-shadow: 0 0 1px $topnav-background-color;
}

.rounded-btn:not(.btn-success), .right-corner-button,
.list-group a.list-group-item,
.panel-title a,
.nested-menu a.list-group-item.list-group-item-not-clickable:hover,
.tfa-page {
  background: $topnav-background-color;
}

.list-group a:hover,
.list-group a.router-link-active,
.panel-title:hover,
.panel-collapse .panel-body .list-group-item:hover {
  color: var(--purple) !important;
}

.table-border {
  border: 1px solid var(--border);
}

.table-colors {
  background: var(--background-primary);
  color: var(--text-primary)
}

//Fast way for defining text color
.text-purple-ionfi {
  color: var(--purple) !important;
}

.text-purple-ionfi-with-transparency {
  color: var(--purple) !important;
  opacity: 0.9 !important;
}

//Fast way for defining background color
.bg-purple-ionfi {
  background: var(--purple) !important;
  color: #FFFFFF;
}

.bg-braid {
  background: $braid-background !important;
  color: $braid-text;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-no-transition {
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);

  &:hover {
    cursor: pointer;
  }
  &.focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.bg-braid-shadow {
  background: $braid-background !important;
  color: $braid-text;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.datatable-column-label-type {
  padding-right: 50px !important;
  text-align: right !important;
}

.bg-button-purple-ionfi {
  background: var(--button-purple) !important;
  color: #FFFFFF;
}

.bg-button-white-ionfi {
  background: var(--button-white) !important;
  color: #FFFFFF;
}

.bg-purple-04 {
  background: rgba(78, 0, 198, 0.04);
}

.bg-theme {
  background-color: var(--background-primary) !important;
}

.cancel-batch-button {
  color: rgba(78, 0, 198, 0.9);
  border: 1px solid rgba(78, 0, 198, 0.16);
}

.text-theme {
  color: var(--text-primary) !important;
}

//Cancel button design for every modal
.cancel-button {
  border: 1px solid var(--border);
  font-size: 14px;
  background-color: rgba(255, 255, 255, 1);
  padding: 10px 12px 10px 12px;
}

//Purple button design for every modal
.purple-button {
  background: var(--purple) !important;
  padding: 10px 30px 10px 30px;
  font-size: 14px;
  color: #FFFFFF;
}

//Cannot change the color or any attribute to the select icon provided from bootstrap dropdown menu, so we delete that one and import custom
.custom-select_arrow-ionfi {

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /* no standardized syntax available, no ie-friendly solution available */
  }

  fa-icon {
    float: right;
    margin-top: -30px;
    margin-right: 5px;
    pointer-events: none;
    color: var(--text-primary-opacity-5);
    padding-right: 5px;
  }
}

//Header text design for each component
.component-body-header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}

.component-item-info {
  display: flex;
  margin-left: 10px;
  border-top: 1px solid #E6E7E9;
  border-bottom: 1px solid #E6E7E9;
  justify-content: space-between;
}

.component-item-text {
  padding: 10px 25px 10px 25px;
  font-size: 14px;
  color: $ionfi-black-opacity-7;
}

.text-color-header {
  color: var(--text-primary-opacity-7);
}

.text-color-body {
  color: var(--text-primary-opacity-9);
}

.table-item-text {
  color: var(--text-primary-opacity-9);
}

.text-dark-0-42-opacity {
  color: var(--text-black-opacity-0-42) !important;
}

.text-dark-7-opacity {
  color: var(--text-black-opacity-7) !important;
}

.text-dark-9-opacity {
  color: var(--text-black-opacity-9) !important;
}

.text-color-braid-ubo {
  color: $braid-background !important;
}

.text-color-braid-ubo:hover {
  color: $braid-background !important;
}

.text-yellow {
  color: var(--text-yellow) !important;
  &:hover {
    color: var(--text-yellow);
  }
}

.answer-row .text-color-braid-ubo:hover {
  color: $braid-background !important;
}

// table header with title and buttons always strict 67px height
.table-header {
  height: 67px;
  padding: 0 20px;

  .component-body-header {
    margin-top: -5px;
  }
}

//Opacity for disabled icons
.opacity-3 {
  opacity: 0.3;
}

//Text wrap from bootstrap missing
.text-wrap {
  white-space: pre-wrap;
}

//Responsivenes for each dashboard nav menu
.dashboard-nav-menu-resp {
  @media only screen and (max-width: 768px) {
    display: block;
    text-align: center;
  }
}

//Responsivenes for each dashboard button
.responsive-button-extra-sm {
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.icon-table-actions {
  background: transparent;
  border: 0;
  color: $ionfi-purple;
  padding: 3px;
}

.border-10 {
  border-radius: 10px;
}

.border-8 {
  border-radius: 8px;
}

.border-4 {
  border-radius: 4px;
}

.hidden-resp {
  display: none;
}

.visible-resp {
  display: block;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-filter {
  .form-group > label {
    color: var(--text-primary) !important;
    margin-bottom: 0.8rem;
  }

  .wire-statuses label {
    margin: 3px;
  }

  input[type=checkbox] {
    width: 18px;
    height: 18px;
    vertical-align: -5px;
  }
}

.type-completed,
.type-complete,
.type-approved,
.type-confirmed,
.type-processed,
.type-submitted,
.type-uploaded-to-rt,
.type-processed-by-rt {
  background: $dark-green-opacity-15;
  color: $text-green;
  width: fit-content;
  border-radius: 100px;
  padding: 1px 12px;
}

.banner-completed {
  background: $dark-green-opacity-15;
}

.border-top-10 {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.type-rejected,
.type-discarded,
.type-settlement-rejected {
  background: $text-red-opacity-15;
  color: $text-red;
  width: fit-content;
  border-radius: 100px;
  padding: 1px 12px;
}

.banner-rejected {
  background: $text-red-opacity-15;
}

.bg-purple-ionfi-16 {
  background: $ionfi-purple-opacity-0-16;
}

.bg-dark-04 {
  background: rgba(86, 87, 88, 0.04);
}

.type-ultimate-beneficiary-required {
  background: $text-blue-opacity-15;
  color: $text-blue;
  width: fit-content;
  border-radius: 100px;
  padding: 1px 12px;
  display: block;
  text-align: center;
}

.type-design-circle {
  width: fit-content;
  border-radius: 100px;
  padding: 1px 16px;
  display: block;
  text-align: center;
  font-weight: normal;
}

.type-pending,
.type-pending-kyc,
.type-pending-idm,
.type-pending-compliance,
.type-more-information-required {
  background: $text-yellow-opacity-15;
  color: $text-yellow;
  width: fit-content;
  border-radius: 100px;
  padding: 1px 12px;
}

.banner-pending {
  background: $text-yellow-opacity-15;
}

.type-blue {
  background: $text-blue-opacity-15;
  color: $text-blue;
  width: fit-content;
  border-radius: 100px;
  padding: 1px 12px;
}

.banner-blue {
  background: $text-blue-opacity-15;
}

.status-rejected {
  background: $text-red-opacity-15;
  color: $text-red;
  width: fit-content;
  border-radius: 100px;
  padding: 5px 12px;
}

.type-processing,
.type-pending-IONFI-approval,
.type-pending-fi,
.type-data-review,
.type-settlement-pending,
.type-pending-first-ionfi,
.type-pending-second-ionfi {
  background: var(--purple-rose);
  color: var(--purple-rose-inverted);
  width: fit-content;
  border-radius: 100px;
  padding: 1px 12px;
}

.banner-status {
  background: var(--purple-rose);
}

.type-status {
  background: var(--purple-rose);
  color: var(--purple-rose-inverted);
  width: fit-content;
  border-radius: 100px;
  padding: 1px 12px;
  display: block;
  text-align: center;
  float: right;
}

.status-complete {
  background: $dark-green-opacity-15;
  color: $text-green;
  width: fit-content;
  border-radius: 100px;
  padding: 5px 12px;
}

.full-width {
  width: 100%;
}

.full-height-body {
  height: calc(100vh - 145px);

  &-no-header {
    height: calc(100vh - 90px);
  }
}

.full-height-body-cards {
  height: calc(100vh - 265px);
}

.full-height-body-rdc {
  height: calc(100vh - 225px);
}

.input-icon {
  background-color: $bright-gray;
  border-left: 1px var(--border) gray !important;
  border-radius: 0 !important;
}

.pointer {
  cursor: pointer !important;
}

.underline-mimic:hover {
  text-decoration: underline !important;
}

.stepper {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.no-border-1st:nth-child(1) {
  border-top: none !important;
}

.stepper-wrapper {
  overflow: hidden;
  outline: 0;
  position: relative;
  box-sizing: content-box;
}

.stepper-flex {
  display: flex;
  height: 72px;
  overflow: visible;
  align-items: center;
}

.stepper-button {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: dimgray;
  color: #fff;
  margin-right: 8px;
}

.step-active {
  background-color: var(--purple) !important;
}

.step-disabled {
  background: #E6E7E9;
}

.text-disabled {
  color: var(--text-primary-opacity-5)
}

.stepper-label {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px;
  vertical-align: middle;
}

.stepper-line {
  border: 1px solid $border;
  height: 0;
  width: 72px;
  margin: 0px 20px 0px 10px;
  // margin: 0px 0px 0px -15px;

}

.info-button-scanner {
  position: absolute;
  top: -7px;
  right: 15px;
}

.download-button {
  color: var(--download-button-text);
  background-color: var(--download-button-background);
  border-radius: 4px;

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active,
  &:focus {
    background-color: var(--download-button-background-focused);
  }
}

.full-height-body-no-tabs {
  height: calc(100vh - 85px);
}

.text-14 {
  font-size: 14px;
}

.text-16 {
  font-size: 16px !important;
}

.fit-content {
  height: fit-content;
}

.text-12 {
  font-size: 12px;
}

.edit-icon {
  background: transparent;
  border: 0;
  padding: 3px;
}

.chart-ico {
  float:right;
  margin-right: 20px;
  transition: none !important;
  &-on {
    margin-top: -335px;
  }
  &-off {
    margin-top: -49px;
  }
}

.last-item-border-bottom {
  border-bottom: 1px solid #e9ecef !important;
}

.slider-ionfi {
  .form-radio:checked, .form-checkbox:checked, input:checked + .slider, .panel-collapse .panel-body .list-group-item {
    background-color: var(--purple) !important;
  }
}

.currency-design-display {
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  background: $purple-rose;
  color: #4E00C6;
  width: fit-content;
  border-radius: 100px;
  padding: 5px 12px;
}

// padding-left for space between the first column and the left end
.pl-22 {
  padding-left: 22px !important;
}

.balance-text-display {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--text-primary);
}

.divider {
  color: var(--border);
}

// fixed height for chart cards
.fixed-card-h {
  height: 270px;
}

.transition-none * {
  -webkit-transition: 0s !important;
  -moz-transition: 0s !important;
  -ms-transition: 0s !important;
  -o-transition: 0s !important;
  transition: 0s !important;
}

.transition-none-all * {
  -webkit-transition: all 0s !important;
  -moz-transition: all 0s !important;
  -ms-transition: all 0s !important;
  -o-transition: all 0s !important;
  transition: all 0s !important;
}


@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon/icomoon.eot?casf8t');
  src: url('../assets/fonts/icomoon/icomoon.eot?casf8t#iefix') format('embedded-opentype'),
  url('../assets/fonts/icomoon/icomoon.ttf?casf8t') format('truetype'),
  url('../assets/fonts/icomoon/icomoon.woff?casf8t') format('woff'),
  url('../assets/fonts/icomoon/icomoon.svg?casf8t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'],
.ico {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-upload:before {
  content: "\e913";
}

.icon-xls-file:before {
  content: "\e912";
  color: #2dca73;
}

.icon-user-plus:before {
  content: "\e911";
}

.icon-scan:before {
  content: "\e910";
}

.icon-people:before {
  content: "\e90f";
}

.icon-info:before {
  content: "\e909";
}

.icon-eye:before {
  content: "\e90d";
}

.icon-pen:before {
  content: "\e908";
  color: var(--purple);
}

.icon-pen-inherit:before {
  content: "\e908";
  color: inherit;
}

.icon-trashcan:before {
  content: "\e90e";
  color: var(--purple);
}

.icon-cash:before {
  content: "\e90a";
}

.icon-withdrawal:before {
  content: "\e90b";
}

.icon-dollar:before {
  content: "\e90c";
}

.icon-file:before {
  content: "\e907";
}

.icon-filter:before {
  content: "\e901";
}

.icon-collapse:before {
  content: "\e902";
}

.icon-users:before {
  content: "\e900";
}

.icon-rdc:before {
  content: "\e903";
}

.icon-kyc:before {
  content: "\e904";
}

.icon-wires:before {
  content: "\e905";
}

.icon-accounts:before {
  content: "\e906";
}

.icon-chart:before {
  content: "\e99b"
}
.icon-clone:before {
  content: "\2750";
  color: var(--purple);
}

.inline-block {
  display: inline-block;
}

.bottom-buttons {
  position: fixed;
  bottom: 0;
  right: 5px;
  width: calc(100% - 245px);
}

.shrink-main-container {
  .bottom-buttons {
    width: calc(100% - 245px) !important;
  }
}

.expand-main-container {
  .bottom-buttons {
    width: calc(100% - 110px) !important;;
  }
}

.clickable {
  cursor: pointer;
}

.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-protected .grecaptcha-badge {
  visibility: visible;
}
